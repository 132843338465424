import React, { useEffect, useState } from "react";
import ProjectGallery from "../projects/ProjectGallery";

const Galleries = ({ data = [], tabIndex }) => {
  if (data.length > 0) {
    return <GalleryTabs tabIndex={tabIndex} data={data} />;
  }
  return null;
};

const GalleryTabs = ({ data, tabIndex }) => {
  const [onTab, setOnTab] = useState(0);

  useEffect(() => {
    setOnTab(0);
  }, [tabIndex]);

  return (
    <>
      <div className="gallery-tabs project-tabs">
        {data.map((x, idx) =>
          x.title ? (
            <button
              key={idx}
              onClick={() => setOnTab(idx)}
              className={`btn${onTab === idx ? ` active` : ""}`}
            >
              {x.title}
            </button>
          ) : (
            ""
          )
        )}
      </div>
      {data[onTab] && data[onTab].gallery && (
        <ProjectGallery gallery={data[onTab].gallery} />
      )}
    </>
  );
};

export default Galleries;
