import React, { useState } from "react";
import Modal from "../modal/Modal";
import Img from "gatsby-image";

function BannerSection({
  title = "",
  tagline = "",
  brouchureUrl = null,
  banner,
  video = null,
  rera = "",
  certificate_number = null,
  bannerLogo = null,
}) {
  return (
    <div className="project-banner d-flex align-items-center relative">
      {banner && banner.childImageSharp && (
        <div className="abs-full">
          <Img
            className="project-banner-img"
            fluid={banner.childImageSharp.fluid}
          />
        </div>
      )}
      <div className="abs-full tint" />
      <div className="container z-index-1">
        <div className="row align-items-center pt-3 pb-3">
          <div className="col-md-8 mb-3 mb-md-0">
            <div className="d-flex align-items-center flex-wrap">
              <div className="flex-grow-1">
                <h2 className="banner-title">{title}</h2>
              </div>
            </div>
            <p className="banner-tagline">{tagline}</p>
            <p>
              {certificate_number && (
                <small style={{ fontSize: "10px" }}>{certificate_number}</small>
              )}
            </p>
            <div>
              {brouchureUrl && (
                <a
                  download
                  href={brouchureUrl}
                  className="btn btn-lg btn-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-Brochure Download
                </a>
              )}
              <br />
              {rera && <small style={{ fontSize: "10px" }}>{rera}</small>}
            </div>
          </div>
          {video && (
            <div className="col-md-4">
              <VideoBtn video={video} />
            </div>
          )}
          {bannerLogo && bannerLogo.childImageSharp && (
            <div className="col-md-4">
              <img
                style={{ maxWidth: "250px" }}
                className="w-100 mt-5 float-right"
                src={bannerLogo.childImageSharp.fluid.src}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const VideoBtn = ({ iconSize = 50, video = {} }) => {
  const [show, set] = useState(false);

  return (
    <>
      <button
        className="btn btn-banner-play d-flex justify-content-center align-items-center float-md-right"
        onClick={() => set(!show)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="#eee"
          stroke="#2c2c2c"
          strokeWidth="0.25"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-play"
        >
          <polygon points="5 3 19 12 5 21 5 3" />
        </svg>
      </button>
      <Modal id="video-modal" contentClass="modal-video" isOpen={show}>
        <button
          onClick={() => set(null)}
          className="btn btn-carousel modal-carousel-close d-flex align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <VideoType {...video} />
      </Modal>
    </>
  );
};

export const VideoType = ({ type = "youtube", link, idx, slideIndex }) => {
  if (type === "youtube") {
    return (
      <iframe
        title="video"
        src={link + (slideIndex == idx ? "?autoplay=1" : "")}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
  if (type === "html5") {
    return (
      <video
        className="w-100"
        autoPlay={slideIndex == idx ? true : false}
        src={link}
        controls
      />
    );
  }
  return null;
};

export default BannerSection;
