import React, { useState, useCallback } from "react";
import Img from "gatsby-image";
import GalleryModal from "./GalleryModal";

const GalleryList = ({
  images = [],
  videos = [],
  conClass = "form-row",
  imgClass = "gallery-thumb",
  thumbClass = "col-6 col-md-3 mb-2",
}) => {
  const [active, set] = useState(null);

  const onClose = useCallback(() => {
    set(null);
  }, []);

  return (
    <div className={conClass}>
      {images.map((img, idx) => (
        <div className={thumbClass} key={idx}>
          <button className="w-100 button-as-link" onClick={() => set(idx)}>
            <Img className={imgClass} fluid={img} alt="" />
          </button>
        </div>
      ))}
      {videos.map((video, idx) => (
        <div className={thumbClass} key={idx}>
          <button
            className="w-100 button-as-link"
            onClick={() => set(images.length + idx)}
          >
            <Img
              className={imgClass}
              fluid={video.thumbnail.childImageSharp.fluid}
              alt=""
            />
          </button>
        </div>
      ))}
      <GalleryModal
        slideIndex={active}
        setSlideIndex={set}
        images={images}
        videos={videos}
        onClose={onClose}
      />
    </div>
  );
};

export default GalleryList;
