import React from "react";
import usePortal from "../hooks/usePortal";
import canUseDom from "../utils/canUseDom";
import { createPortal } from "react-dom";

const Portal = ({ id, children }) => {
  if (canUseDom) {
    return <CreatePortal id={id}>{children}</CreatePortal>;
  }
  return null;
};

const CreatePortal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

export default Portal;
