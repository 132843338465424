import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import { graphql, useStaticQuery } from "gatsby";
// import Img from "gatsby-image"
import GalleryList from "../components/gallery/GalleryList";
import Galleries from "../components/gallery/Galleries";

const MediaSection = ({ publishedOn = "", images = [] }) => {
  return (
    <div className="pb-4">
      <h5 className="residence-section-title">{publishedOn}</h5>
      <GalleryList images={images.map((x) => x.childImageSharp.fluid)} />
    </div>
  );
};

function Media() {
  const data = useMediaData();

  const prominent = [data.allMediaJson.edges[0]];
  const medias = [];
  data.allMediaJson.edges.slice(1).forEach(({ node }) => {
    const year = node.publishedOn.substring(4, 8);
    const mediaIndex = medias.findIndex((o) => o.title === year);
    if (mediaIndex !== -1) {
      medias[mediaIndex].gallery.unshift({
        title: node.publishedOn,
        images: node.images,
      });
    } else {
      medias.push({
        title: year,
        gallery: [
          {
            title: node.publishedOn,
            images: node.images,
          },
        ],
      });
    }
  });

  return (
    <Layout>
      <HeroSection title="Media" />
      <section className="residence-section">
        <div className="container">
          {prominent.map(({ node }) => (
            <MediaSection {...node} key={node.id} />
          ))}

          <Galleries data={medias} />
        </div>
      </section>
    </Layout>
  );
}

export const useMediaData = () =>
  useStaticQuery(graphql`
    {
      allMediaJson(sort: { fields: publishedOn, order: DESC }) {
        edges {
          node {
            id
            publishedOn(formatString: "MMM YYYY")
            images {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

export default Media;
